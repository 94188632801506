import React, { useState, useRef } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import axios from "axios"
import { useForm } from "react-hook-form"
import { CheckCircle, AlertCircle } from 'react-feather';
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_SITE_KEY, FORMSPREE_URL } from '../utils/constants'

const Contact = () => {

	const { register, handleSubmit, reset, errors } = useForm();
	
	const [captchaValue, setCaptchaValue] = useState();
	const captchaRef = useRef(null);

    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });

    const handleServerResponse = (ok, msg) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            reset({
                name: '',
                email: '',
				message: ''
            });
        }
		setCaptchaValue(null);
		captchaRef && captchaRef.current && captchaRef.current.reset();
    };

    const onSubmit = ({name, email, message, phone}) => {
        setServerState({ submitting: true });
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
		formData.append("message", message);
       	formData.append('g-recaptcha-response', captchaValue);
        axios({
            method: "post",
        	url: FORMSPREE_URL,
            data: formData
        }).then(_ => {
            handleServerResponse(true, "Thanks, your message has been sent!");
        }).catch(r => {
            handleServerResponse(false, "Oops, there was an error sending your message: " + r.response.data.error);
        });
    };

    const handleCaptchaChange = value => {
        setCaptchaValue(value);
	}
	
	return(
		<Layout>
			<SEO title="Contact" />
			<div className='card'>
				<div className="card-body">
					<div className="row">
						<div className="col-md-6 mx-auto">
							<h1>Get In Touch &ndash; Say Hello!</h1>
							<p className='mb-3'>If you have any questions or comments or just want to know more:</p>
							{serverState.status && (
								<p className={serverState.status.ok ? "successMsg" : "errorMsg"}>
									{serverState.status.ok ? <CheckCircle /> : <AlertCircle />}
									{serverState.status.msg}
								</p>
							)} 

							{(!serverState.status || (serverState.status && !serverState.status.ok)) && (
								<form onSubmit={handleSubmit(onSubmit)} className='mb-1'>
									<div className="basic-form__group">
										<input
											name="name"
											className="basic-form__text-box"
											ref={register({
												required: 'Please enter your name',
											})}
											type="text"
											placeholder="Name*"
										/>
									</div>
									{errors.name && <p className='basic-form__hint'>{errors.name.message}</p>}
					
									<div className="basic-form__group">
										<input
											name="email"
											className="basic-form__text-box"
											ref={register({
												required: 'Please enter your email',
											})}
											type="email"
											placeholder="Email*"
										/>
									</div>
									{errors.email && <p className='basic-form__hint'>{errors.email.message}</p>}
									<div className="basic-form__group">
										<textarea
											name="message"
											className="basic-form__text-box"
											ref={register({
												required: 'Please write your message',
											})}
											placeholder="Message*"
											rows="4"
										></textarea>
										{errors.message && <p className='basic-form__hint mt-0'>{errors.message.message}</p>}
									</div>
									<div className="mb-2">
										<ReCAPTCHA
											ref={r => captchaRef.current = r}
											sitekey={GOOGLE_SITE_KEY}
											onChange={(e) => handleCaptchaChange(e)}
										/>
									</div>
									<button type="submit" disabled={serverState.submitting || !captchaValue} className='btn'>{serverState.submitting ?  'Sending' : 'Send'}</button>
									{/* <button type="submit" disabled={serverState.submitting} className='btn'>{serverState.submitting ?  'Sending' : 'Send'}</button> */}

								</form>
							)} 
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)

}
export default Contact
